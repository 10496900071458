<template>
    <div class="md-modal">
        <h1 class="reward__title">{{isUpdateMode ? $t('btSave') : $t('btAdd')}}</h1>
        <ap-form
                :fields="fields"
                :values="notify"
                :buttons="buttons"
                @change="save"
                @close="$emit('close')"
        ></ap-form>
    </div>
</template>

<script>
    import ApForm from "../../components/ApForm"

    export default {
        name: "UpdatePushNotification",
        props: ['id'],
        components: {
            ApForm
        },
        data() {
            return {

            }
        },
        computed: {
            // Form mode
            isUpdateMode() {
                // Return mode
                return this.id ? 1 : 0;
            },
            languages() {
                let returnData = {}
                this.$store.getters['translation/languages'].map(item => {
                    returnData[item.id] = item.language
                })
                return returnData
            },
            fields() {
                let fields = {
                    language: {
                        type: 'select',
                        title: 'Language',
                        options: this.languages,
                        validation: ["required"]
                    },
                    title: {
                        type: 'string',
                        title: 'Title',
                        validation: ["required"]
                    },
                    description: {
                        type: 'textarea',
                        title: 'Description',
                        validation: ["required"]
                    },
                    active: {
                        type: 'switch',
                        title: 'Activity',
                    },
                    recipients: {
                        type: 'checkbox',
                        title: 'Recipients',
                        options: [
                            {
                                title: 'Adult',
                                val: 'adult'
                            },
                            {
                                title: 'Children',
                                val: 'child'
                            }
                        ],
                        validation: ["required"]
                    },
                    days: {
                        type: 'select',
                        title: 'Day of week',
                        multiple: true,
                        options: {
                            'mon': 'Monday',
                            'tue': 'Tuesday',
                            'wed': 'Wednesday',
                            'thu': 'Thursday',
                            'fri': 'Friday',
                            'sat': 'Saturday',
                            'sun': 'Sunday',
                        },
                        validation: ["required"]
                    },
                    time: {
                        type: 'select',
                        title: 'Time',
                        options: {
                          '00:00:00': '00:00',
                          '01:00:00': '01:00',
                          '02:00:00': '02:00',
                          '03:00:00': '03:00',
                          '04:00:00': '04:00',
                          '05:00:00': '05:00',
                          '06:00:00': '06:00',
                          '07:00:00': '07:00',
                          '08:00:00': '08:00',
                          '09:00:00': '09:00',
                          '10:00:00': '10:00',
                          '11:00:00': '11:00',
                          '12:00:00': '12:00',
                          '13:00:00': '13:00',
                          '14:00:00': '14:00',
                          '15:00:00': '15:00',
                          '16:00:00': '16:00',
                          '17:00:00': '17:00',
                          '18:00:00': '18:00',
                          '19:00:00': '19:00',
                          '20:00:00': '20:00',
                          '21:00:00': '21:00',
                          '22:00:00': '22:00',
                          '23:00:00': '23:00',
                        },
                        validation: ["required"]
                    }

                }
                return fields
            },
            buttons() {
                // Buttons list
                let btns = {close: true};

                // Save button
                btns['save'] = {
                    title: this.isUpdateMode ? this.$t('btSave') : this.$t('btAdd'),
                    class: "md-primary md-raised",
                    type: "submit"
                }

                // Return list
                return btns;
            },
            notify() {
                let val = {}
                if (this.id) {
                    val = this.$get('notifications/get', this.id)
                    if (val.active === 1) {
                        val.active = '1'
                    }
                } else {
                    val.active = '1'
                }


                return val
            }

        },
        methods: {
            async save(values) {
                // /push-notification/create
                if (!values.active) {
                    values.active = '0'
                }
                await this.$dispatch('notifications/create', values)
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
 .md-modal {
     padding: 16px;
 }
</style>
