<template>
    <div class="push-notif">
        <md-dialog
                :md-active.sync="showDialog"
                :md-fullscreen="false"
                class="popup-dialog"
        >
            <update-push-notification
                    :id="notify_id"
                    templateMode="true"
                    @close="showDialog=false"
            />
        </md-dialog>
        <md-table v-model="notifications">
            <md-table-toolbar>
                <h1 class="md-title">Push notifications</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID" md-sort-by="id" md-numeric><a href="#" @click.prevent="update(item)">{{item.id}}</a></md-table-cell>
                <md-table-cell md-label="Language" md-sort-by="id">{{ item.language }}</md-table-cell>
                <md-table-cell md-label="Day to send" md-sort-by="name">{{ item.days }}</md-table-cell>
                <md-table-cell md-label="Time to send" md-sort-by="name">{{ item.time }}</md-table-cell>
                <md-table-cell md-label="Title" md-sort-by="email">{{ item.title }}</md-table-cell>
                <md-table-cell md-label="Recipients" md-sort-by="gender">{{ item.recipients }}</md-table-cell>
                <md-table-cell md-label="Active" md-sort-by="gender">{{ item.active ? 'active' : 'disabled' }}</md-table-cell>
                <md-table-cell md-label="Action">
                    <md-button @click="deleteItem(item)">
                        <md-icon>delete_outline</md-icon>
                    </md-button>
                </md-table-cell>
            </md-table-row>
        </md-table>

        <md-button class="md-fab md-primary md-fab-bottom-right" @click="add">
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>
    import UpdatePushNotification from "./UpdatePushNotification";
    export default {
        name: "PushNotifications",
        components: {UpdatePushNotification},
        data() {
            return {
                showDialog: false,
                notify_id: 0
            }
        },
        computed: {
            notifications() {
                return this.$get('notifications/list')
            }
        },
        methods: {
            add() {
                this.notify_id = 0
                this.showDialog = !this.showDialog
            },

            deleteItem(item) {
                if (confirm(`Do you confirm to delete ID ${item.id}?`)) {
                    this.$dispatch('notifications/delete', item)
                }
            },

            update(item) {
                this.showDialog = true
                this.notify_id = item.id
            }
        }
    }
</script>

<style lang="scss">
    .push-notif {
        width: 100%;
        min-height: 80vh;
        .md-fab-bottom-right {
            position: fixed;
            bottom: 0;
            right: 50px;
        }
    }
</style>
